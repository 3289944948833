import React, { useEffect } from "react";
import styles from "./promotions.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import {
  getPromotionCardDataFailure,
  getPromotionCardDataSuccess,
  promotionCardDataRequest,
} from "../../slice/promotionCardSlice";
import { PromoAdcarousel, PromotionCardData } from "../../models/models";
import TGIcon from "../../../../shared/tg-icon";
import TGPromoCarousel from "../../../../shared/tg-promo-carousel";
import { config } from "../../../../config/global";
import { Link } from "react-router-dom";

const Promotion = () => {
  const dispatch = useDispatch();
  const promotionData = useSelector(
    (state: RootState) => state?.osciReducer?.promotionCard.promotionDetails
  );
  const promoLoading = useSelector(
    (state: RootState) => state?.osciReducer?.promotionCard.isLoading
  );

  const promotionCarouselData = promotionData
    .map((x: PromotionCardData) => {
      return x.promoADCarousel?.map((x: PromoAdcarousel, index: number) => {
        return {
          ...x,
          key: index,
          image: x.promoADImage,
          altText: x.promoADImageAltText,
          title: x.promoADHeader,
          subTitle: x.promoAd,
          description: x.promoADDescription,
          buttonText: x.promoADBtnText,
          buttonLink: x.promoADURL,
        };
      });
    })
    .flat();

  const API_URL = config.TEAMSITE_PROMOTIONS_API;

  useEffect(() => {
    // dispatch(promotionCardDataRequest());
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: API_URL,
      isTeamsite: true,
      successAction: getPromotionCardDataSuccess,
      errorAction: getPromotionCardDataFailure,
    });
  }, [dispatch]);

  const PromotionCard = () => {
    return promotionData?.map((x: PromotionCardData) => (
      <div className={styles.cardContainer}>
        <span className={styles.promoHeader} role="heading">
          {x.promoHeader}
        </span>
        <span className={styles.promoDesc}>{x.promoDescription}</span>
        <div className={styles.reDirDiv}>
          <Link
            className={styles.reDirTxt}
            to={x.promoReDirURL}
            target="_blank"
            aria-label="see-all-promotions"
          >
            {x.promoReDirText}
          </Link>
          <TGIcon icon="arrow-right-sm" fillColor="" size="20" />
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <PromotionCard />
        <div className={styles.carouselContainer}>
          <TGPromoCarousel
            promoData={promotionCarouselData}
            isLoading={promoLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Promotion;
